<template>
    <div>
        <header-layout />
        <slot />
        <footer-layout />
    </div>
</template>

<script>
import HeaderLayout from './LayoutHeader.vue';
import FooterLayout from './LayoutFooter.vue';

export default {
    name: 'DefaultLayout',
    components: {
        HeaderLayout,
        FooterLayout
    },
    mounted() {
        document.title = 'Das Viavitum | Das spürbar andere Therapiezentrum in Windsbach';
    }
};
</script>
