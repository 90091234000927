import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from '../router';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const headers = { Accept: 'application/json' };

export default new Vuex.Store({
    state: {
        currentJoke: 'This is a joke'
    },
    mutations: {
        setCurrentJoke(state, payload) {
            state.currentJoke = payload;
        }
    },
    actions: {
        async setCurrentJoke(state) {
            const joke = await fetch('http://localhost:5000/get_joke/', { headers });
            const j = await joke.json();
            state.commit('setCurrentJoke', j.joke);
        },
        async saveNewJoke(state, payload) {
            await axios.post('http://localhost:5000/set_joke/', { joke: payload });
            router.push('/jokes');
        }
    },
    modules: {

    },
    getters: {
        getCurrentJoke(state) {
            return state.currentJoke;
        }
    }
});
