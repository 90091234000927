<template>
    <footer class="footer pt-0">
        <section class="contact py-5 bg-primary text-white">
        <div class="container">
            <div class="row gy-4">
            <div class="col-lg-4">
                <div class="d-flex align-items-center">
                <div class="contact-icon">
                    <font-awesome-icon icon="phone" />
                </div>
                <div class="ms-3">
                    <h3 class="h5 mb-0"><a style="color: white" v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a></h3>
                    <p class="small mb-0">{{ $t('frame.call_us') }}</p>
                </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="d-flex align-items-center">
                <div class="contact-icon">
                    <font-awesome-icon :icon="['fab', 'whatsapp']" />
                </div>
                <div class="ms-3">
                    <h3 class="h5 mb-0 text-break"><a style="color:white" v-bind:href="`https://wa.me/4998716579801`">{{ $t('frame.phone') }}</a></h3>
                    <p class="small mb-0">chatten Sie mit uns auf WhatsApp</p>
                </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="d-flex align-items-center">
                <div class="contact-icon">
                    <font-awesome-icon icon="envelope" />
                </div>
                <div class="ms-3">
                    <h3 class="h5 mb-0 text-break"><a style="color:white" v-bind:href="`mailto:${$t('frame.email')}`">{{ $t('frame.email') }}</a></h3>
                    <p class="small mb-0">{{ $t('frame.or_write_email') }}</p>
                </div>
                </div>
            </div>
            <div class="offset-lg-4 col-lg-4 mt-5">
                <div class="d-flex align-items-top">
                <div class="contact-icon">
                    <font-awesome-icon icon="clock" />
                </div>
                <div class="ms-3">
                    <h3 class="h5 mb-0">{{ $t('frame.we_are_open') }}</h3>
                    <table>
                        <tr>
                            <td class="text-right pr-2">Mo-Do:</td>
                            <td >08-12 / 13-18 Uhr</td>
                        </tr>
                        <tr>
                            <td class="text-right pr-2">Fr:</td>
                            <td>08-12 Uhr</td>
                        </tr>
                    </table>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <div class="container pt-5">
            <div class="row gy-4">
            <div class="col-lg-3">
                <div> <!-- Windsbach -->
                    <p class="text-muted">
                        <span class="ml-2"><strong> Windsbach</strong></span><br/>
                    </p>
                    <div class="text-muted text-sm ">
                        <div class="d-flex">
                            <div class="d-inline">
                                <p class="mb-0">
                                        <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
                                </p>
                            </div>
                            <div class="d-inline mx-2">
                                <p class="mb-0">
                                        <span> Heinrich-Brandt-Straße 25</span><br/>
                                        <span> 91575 Windsbach</span>
                                </p>
                            </div>
                        </div>
                        <p class="my-2">
                            <font-awesome-icon :icon="['fas', 'phone']" />
                            <span> <a class="text-muted" v-bind:href="`tel:${$t('frame.phone')}`"> {{ $t('frame.phone') }}</a></span>
                        </p>
                    </div>
                </div>
                <hr class="bg-white">
                <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                        <a class="social-link text-success" href="https://www.instagram.com/das_viavitum/">
                            <font-awesome-icon :icon="['fab', 'instagram']" />
                            <span class="mx-2"><small>Folgt uns auf Instagram!</small></span>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="social-link text-success" href="https://www.tiktok.com/@das_viavitum">
                            <font-awesome-icon :icon="['fab', 'tiktok']" />
                            <span class="mx-2"><small>Wir sind auch auf TikTok...</small></span>
                        </a>
                    </li>

                    <li class="list-inline-item">
                        <a class="social-link text-success" href="https://www.youtube.com/channel/UC3AC5puRr9kXBsJv7KOr8XQ">
                            <font-awesome-icon :icon="['fab', 'youtube']" />
                            <span class="mx-2"><small>Aboniert unseren Kanal.</small></span>
                        </a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-5">
                <h2 class="h5 text-white lined mb-4">Quick Links</h2>
                <div class="d-flex">
                <ul class="list-unstyled d-inline-block me-4 mb-0">
                    <li class="mb-2">
                        <router-link to="/#termin" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.appointment') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/leistungen" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.services') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/ueber" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.team') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/karriere" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.career') }}
                        </router-link>
                    </li>
                </ul>
                <ul class="list-unstyled d-inline-block mb-0">
                    <li class="mb-2">
                        <router-link to="/" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.home') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/ueber" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.about') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/kontakt" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.contact') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/datenschutz" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.privacy') }}
                        </router-link>
                    </li>
                    <li class="mb-2">
                        <router-link to="/impressum" tag="a" class="footer-link" active-class="" exact-active-class="">
                            <small><font-awesome-icon :icon="['far', 'circle']" /></small> {{ $t('frame.imprint') }}
                        </router-link>
                    </li>
                </ul>
                </div>
            </div>
            <div class="col-lg-4">
                <h2 class="h5 text-white lined mb-4">{{$t('frame.newsletter')}}</h2>
                <p class="text-muted text-sm">{{$t('frame.newsletter_text')}}</p>
                <div class="input-group mb-3">
                    <input class="form-control text-muted bg-none border-primary" type="text" v-model="email" v-bind:placeholder="$t('frame.email_placeholder')" aria-label="Recipient's username" aria-describedby="button-addon2">
                    <button class="btn btn-primary btn-sm" id="button-addon2" :disabled="!enableSubmit" v-on:click="sendNewsletter" type="button"><font-awesome-icon :icon="['fas', 'paper-plane']" /></button>
                </div>
                <p class="text-success" v-if="success">Vielen Dank für Deine Anmeldung! Neuigkeiten rund ums Thema Gesundheit landen bald in Deinem Postfach.</p>
                <p class="text-danger" v-if="duplicate">Du bist schon für den Newsletter angemeldet. Neuigkeiten rund ums Thema Gesundheit landen bald in Deinem Postfach!</p>
                <p class="text-danger" v-if="error">Oops. Da ist etwas schief gelaufen. Probiers gerne nochmal oder ruf uns einfach an: <a v-bind:href="`tel:${$t('frame.phone')}`">{{ $t('frame.phone') }}</a></p>
            </div>
            </div>
        </div>
        <div class="copyrights">
            <div class="container text-center py-4">
                <p class="mb-0 text-muted text-sm">&copy; {{ year }}, Das Viavitum</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterLayout',
    data: () => ({
        year: new Date().getFullYear(),
        success: false,
        duplicate: false,
        error: false,
        email: ''
    }),
    computed: {
        enableSubmit() {
            return this.email !== '' && (!this.duplicate) && (!this.success);
        }
    },
    methods: {
        sendNewsletter() {
            this.success = false;
            this.duplicate = false;
            this.error = false;
            this.axios.post(`${process.env.VUE_APP_ROOT_API}newsletter/`, {
                email: this.email
            }).then((response) => {
                if (response.data.status === 1) {
                    this.success = true;
                } else if (response.data.status === 2) {
                    this.duplicate = true;
                } else {
                    this.error = true;
                }
            }).catch(() => {
                this.error = true;
            });
        }
    },
    mounted() {
    }
};
</script>
